<template>
    <meta name="robots" content="index, follow">
    <meta name="description" content="simpos is a powerful, cloud-based point of sale system designed for small and medium businesses. Easily manage sales, inventory, and customer data from anywhere. Try simPOS at simpos.co.ke for a simple, reliable solution tailored to grow your business.">
    <body class="flex-1 min-h-screen flex flex-col">
        <header class="px-4 py-2 flex justify-between bg-blue-500">
            <h1 class="text-2xl font-serif font-semibold lowercase text-gray-100">simpos</h1>
            <div class="md:hidden">
                <SpecialNavLink :href="route('auth.show.signin')" class="w-full" :active="route().current('auth.show.signin')" title="Login">
                    <span class="text-center w-full">Login</span>
                </SpecialNavLink>
            </div>
            <ul :class="showMenu ? 'flex' : 'hidden md:flex'" class="justify-center gap-y-2 z-50 items-center fixed h-screen md:h-min md:w-2/3 w-screen left-0 top-0 bg-blue-500/90 md:bg-inherit md:relative flex-col md:gap-x-4 md:flex-row md:flex-nowrap">
                <li class="md:hidden">
                    <button @click="showMenu = false" title="close" class="text-gray-600 hover:text-gray-900 absolute right-4 top-2">
                        <font-awesome-icon class="text-2xl text-gray-100 font-medium" icon="times" />
                    </button>
                </li>
                <li class="w-4/5 md:w-min">
                    <SpecialNavLink :href="route('guest.home')" class="w-full" :active="route().current('guest.home')" title="Home"> 
                        <span class="text-center w-full">Home</span>
                    </SpecialNavLink>
                </li>
                <li class="w-4/5 md:w-min">
                    <SpecialNavLink :href="route('guest.about')" class="w-full" :active="route().current('guest.about')" title="About Us">
                        <span class="text-center w-full">About</span>
                    </SpecialNavLink>
                </li>
                <li class="w-4/5 md:w-min">
                    <SpecialNavLink :href="route('guest.contact')" class="w-full" :active="route().current('guest.contact')" title="Contact Us">
                        <span class="text-center w-full">Contact</span>
                    </SpecialNavLink>
                </li>
                <li class="w-4/5 md:w-min">
                    <SpecialNavLink :href="route('guest.pricing')" class="w-full" :active="route().current('guest.pricing')" title="Pricing">
                        <span class="text-center w-full">Pricing</span>
                    </SpecialNavLink>
                </li>
                <li class="w-4/5 md:w-max">
                    <SpecialNavLink :href="route('auth.show.signup')" class="w-full" :active="route().current('auth.show.signup')" title="Create Account">
                        <span class="text-center w-full">Create Account</span>
                    </SpecialNavLink>
                </li>
                <li class="w-4/5 md:w-min">
                    <SpecialNavLink :href="route('auth.show.signin')" class="w-full" :active="route().current('auth.show.signin')" title="Login">
                        <span class="text-center w-full">Login</span>
                    </SpecialNavLink>
                </li>
                <li class="w-4/5 md:w-max">
                    <SpecialNavLink :href="route('guest.affiliate')" class="w-full" :active="route().current('guest.affiliate')" title="Become an affiliate">
                        <span class="text-center w-full">Become an affiliate</span>
                    </SpecialNavLink>
                </li>
            </ul>
            <div class="md:hidden">
                <button @click="showMenu = ! showMenu" title="Menu" class="flex items-center p-2 rounded-md mr-1 text-gray-800 dark:text-gray-200 hover:text-gray-500 dark:hover:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-800 focus:outline-none transition duration-150 ease-in-out">
                <font-awesome-icon icon="bars" class="text-lg text-gray-100" />
            </button>
            </div>
        </header>

        <main class="flex-1 flex flex-col bg-white">
            <slot/>
        </main>

        <footer class="px-4 py-6 bg-blue-500 text-gray-100">
            <div class="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-4">
                <div class="text-base text-gray-200">
                <p class="mb-2 font-semibold">Contact Us:</p>
                <p>Email: 
                    <a title="Email us" :href="`mailto:support@${appName}.co.ke`">support@{{appName}}.co.ke</a>
                </p>
                <p class="flex flex-wrap gap-x-1">
                    <span>Phone:</span> 
                    <a title="Call us"ref="tel:+254757882646">+254757882646</a> /
                    <a title="Call us"ref="tel:+254716798067">+254716798067</a>
                </p>
                <NavLink :href="route('guest.contact')">
                    <span class="text-gray-100">Contact us</span>
                </NavLink>
            </div>

        <!-- Company Info Section -->
        <div class="flex flex-col space-y-3">
            <h3 class="font-bold">About {{ appName }}</h3>
            <p>{{ slogan }}</p>
            <div>
                <NavLink :href="route('guest.about')">
                    <span class="text-gray-100">Learn more</span>
                </NavLink>
            </div>
            <div class="hidden">
                <NavLink :href="route('guest.faqs')">
                    <span class="text-gray-100">FAQs</span>
                </NavLink>
            </div>
        </div>

        <!-- Legal & Contact Links -->
        <div class="flex flex-col space-y-2 text-base">
            <h3 class="font-bold">Legal</h3>
            <ul class="space-y-2">
                <li>
                    <NavLink :href="route('guest.terms')" :active="route().current('guest.terms')">
                        <span class="text-gray-100 text-base">Terms of Service</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink :href="route('guest.privacy.policy')" :active="route().current('guest.privacy.policy')">
                        <span class="text-gray-100 text-base">Privacy Policy</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink :href="route('guest.cookie.policy')" :active="route().current('guest.cookie.policy')">
                        <span class="text-gray-100 text-base">Cookie Policy</span>
                    </NavLink>
                </li>
            </ul>
        </div>
        
        <!-- Social Media Links -->
        <div class="flex flex-col">
            <h3 class="font-bold">Follow Us</h3>
            <ul class="flex gap-x-4">
                <li>
                    <a href="https://wa.me" class="hover:underline" target="_blank" title="Our Whatsapp channel">
                        <font-awesome-icon icon="fa-brands fa-whatsapp" class="text-lg md:text-2xl" />
                    </a>
                </li>
                <li>
                    <a href="https://facebook.com" class="hover:underline" target="_blank" title="Our Facebook page">
                        <font-awesome-icon icon="fa-brands fa-facebook" class="text-lg md:text-2xl" />
                    </a>
                </li>
                <li>
                    <a href="https://linkedin.com" class="hover:underline" target="_blank" title="Our LinkedIn page">
                        <font-awesome-icon icon="fa-brands fa-linkedin" class="text-lg md:text-2xl" />
                    </a>
                </li>
            </ul>
        </div>
    </div>
    
    <!-- Bottom Footer -->
    <div class="mt-6 text-center text-sm text-gray-200">
        &copy; {{ currentYear }} {{ appName }}. All Rights Reserved
    </div>
    <BackToTop/>
</footer>

    </body>
</template>

<script setup>
    import SpecialNavLink from '@/Components/SpecialNavLink.vue';
    import NavLink from '@/Components/NavLink.vue';
    import helpers from '@/globals/helpers';
    import BackToTop from '@/Components/BackToTop.vue';
    import { ref } from 'vue';

    const date = new Date();
    const currentYear = date.getFullYear();
    const appName = helpers.appName;
    const slogan = helpers.slogan;
    const showMenu = ref(false);
</script>