<template>
    <div>
      <button
        v-show="isVisible"
        class="fixed bottom-5 right-5 bg-blue-600 text-white p-3 rounded-full hover:bg-blue-700"
        @click="scrollToTop"
      >
        <font-awesome-icon icon="fa-solid fa-arrow-up" class="text-lg" />
      </button>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, onUnmounted } from 'vue';
  
  const isVisible = ref(false);
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  
  const handleScroll = () => {
    isVisible.value = window.scrollY > 300;
  };
  
  onMounted(() => {
    window.addEventListener('scroll', handleScroll);
  });
  
  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
  });
  </script>
  